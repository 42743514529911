import React from 'react'
import type { AppProps } from 'next/app'
import PortalProvider from '@shopper/app/provider/common/portal'
import SupabaseProvider from '@shopper/app/provider/common/supabase'
import { BaseToast } from '@shopper/ui/src/components/BaseToast'
import { ToastProvider, ToastViewport } from '@tamagui/toast'
import AppStateProvider from './appState'
import AuthProvider from './auth'
import NetInfoProvider from './netinfo'
import EnablePosthogProvider from './posthog'
import ReduxProvider from './redux'
import Route from './route'
import RouteProvider from './router'
import ThemeProvider from './theme'
import { TRPCProvider } from './trpc'
import UrlProvider from './url'

const Provider: React.FC<
  AppProps & {
    children: React.ReactNode
  }
> = ({ children, ...appProps }) => {
  return (
    <SupabaseProvider {...appProps}>
      <TRPCProvider>
        <PortalProvider>
          <ThemeProvider {...appProps}>
            <ToastProvider>
              <NetInfoProvider>
                <ReduxProvider>
                  <AuthProvider>
                    <AppStateProvider>
                      <EnablePosthogProvider>
                        <RouteProvider>
                          <Route>
                            <UrlProvider>{children}</UrlProvider>
                          </Route>
                          <BaseToast />
                        </RouteProvider>
                      </EnablePosthogProvider>
                    </AppStateProvider>
                  </AuthProvider>
                </ReduxProvider>
                <ToastViewport
                  bottom={120}
                  justifyContent="center"
                  display="flex"
                  alignSelf="center"
                  multipleToasts
                />
              </NetInfoProvider>
            </ToastProvider>
          </ThemeProvider>
        </PortalProvider>
      </TRPCProvider>
    </SupabaseProvider>
  )
}

export default Provider
