import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from '@shopper/app/store'
import isNil from 'lodash.isnil'
import { $Enums } from '@centrito/db'

const selectSelf = (state: RootState): RootState => state

// AUTH
export const selectAuth = createSelector(selectSelf, (state) => state.auth)
export const selectAuthLoaded = createSelector(selectSelf, selectAuth, (_, auth) => auth.authLoaded)
export const selectAuthenticatedData = createSelector(selectAuth, (auth) => auth.authenticatedData)
export const selectIsAuthenticated = createSelector(
  selectAuth,
  (auth) => !isNil(auth.authenticatedData),
)
export const selectUserId = createSelector(
  selectAuthenticatedData,
  (authenticatedData) => authenticatedData?.userId || null,
)
export const selectIsRegistered = createSelector(
  selectAuthenticatedData,
  (authenticatedData) => !isNil(authenticatedData) && authenticatedData.isRegistered,
)
export const selectUserRole = createSelector(
  selectAuthenticatedData,
  (authenticatedData) => authenticatedData?.role || null,
)
export const selectUserPhoneNumber = createSelector(
  selectAuthenticatedData,
  (authenticatedData) => authenticatedData?.phone ?? null,
)

// USER DATA
/// USER PROFILE
export const selectUserProfile = createSelector(selectSelf, (state) => state.userData.userProfile)
export const selectUserGovernmentID = createSelector(
  selectUserProfile,
  (userProfile) => userProfile?.governmentId ?? null,
)
export const selectUserEmail = createSelector(
  selectUserProfile,
  (userProfile) => userProfile?.email ?? null,
)
/// CART
export const selectCart = createSelector(selectSelf, (state) => state.userData.cart)
export const selectCartItems = createSelector(selectCart, (cart) => cart.items ?? [])
export const selectCartProductIds = createSelector(selectCartItems, (items) =>
  items.map((item) => item.product.product.id),
)
export const selectCartDiscountData = createSelector(selectCart, (cart) => cart.cart.discountData)
export const selectCartDeliveryAddress = createSelector(
  selectCart,
  (cart) => cart.cart.deliveryData,
)
export const selectCartDeliveryDate = createSelector(selectCart, (cart) => cart.cart.deliveryDate)
export const selectCartPricingData = createSelector(
  selectSelf,
  selectCart,
  (_, cart) => cart.pricingData,
)
export const selectCouponOrder = createSelector(
  selectSelf,
  selectCartDiscountData,
  (_, discountData) => discountData?.couponOrder ?? null,
)
export const selectCouponItems = createSelector(
  selectSelf,
  selectCartDiscountData,
  (_, discountData) => discountData?.couponItems ?? [],
)
export const selectCoupons = createSelector(
  selectSelf,
  selectCouponOrder,
  selectCouponItems,
  (_, couponOrder, couponItems) => [...(couponOrder ? [couponOrder] : []), ...couponItems],
)
export const selectCouponOrderUserPromotionId = createSelector(
  selectSelf,
  selectCouponOrder,
  (_, couponOrder) => couponOrder?.userPromotionId ?? null,
)
export const selectCouponOrderCampaignRules = createSelector(
  selectSelf,
  selectCouponOrder,
  (_, couponOrder) => couponOrder?.campaign.rules ?? null,
)
/// OTHER
export const selectLastPickedStore = createSelector(
  selectSelf,
  (state) => state.userData.selectedStore,
)
export const selectAnonymousUserId = createSelector(
  selectSelf,
  (state) => state.userData.anonymousUserId,
)
export const selectAnonymousCartId = createSelector(
  selectSelf,
  (state) => state.userData.anonymousCartId,
)
export const selectSearchedTerms = createSelector(
  selectSelf,
  (state) => state.userData.searchedTerms,
)
export const selectSessionProductIds = createSelector(
  selectSelf,
  (state) => state.userData.sessionProductIds,
)
export const selectIsEmailNotificationEnabled = createSelector(
  selectSelf,
  (state) => state.userData.isEmailNotificationsEnabled,
)

/// CHECKOUT
export const selectCheckoutIsActionEnabled = createSelector(
  selectSelf,
  (state) => state.userData.checkout.isActionEnabled,
)
export const selectCheckoutDeliveryType = createSelector(
  selectSelf,
  (state) => state.userData.checkout.deliveryStepState.selectedType ?? undefined,
)
export const selectCheckoutFirstStepCompleteness = createSelector(
  selectCheckoutDeliveryType,
  selectCartDeliveryAddress,
  selectLastPickedStore,
  (deliveryType, domicile, store) => {
    if (isNil(deliveryType)) {
      return false
    }
    if (
      deliveryType === $Enums.OrderDeliveryDestinationType.LOCAL_HOME ||
      deliveryType === $Enums.OrderDeliveryDestinationType.NATIONAL
    ) {
      return !isNil(domicile)
    }
    if (deliveryType === $Enums.OrderDeliveryDestinationType.LOCAL_ALLIED_STORE) {
      return !isNil(store)
    }
    return false
  },
)
export const selectCheckoutPaymentMethod = createSelector(
  selectSelf,
  (state) => state.userData.checkout.paymentStepState.selectedMethod ?? undefined,
)
export const selectCheckoutPalommaBank = createSelector(
  selectSelf,
  (state) => state.userData.checkout.paymentStepState.selectedBank ?? null,
)
export const selectCheckoutPalommaBankName = createSelector(
  selectSelf,
  (state) => state.userData.checkout.paymentStepState.selectedBankName ?? null,
)
// MERCHANT DATA
export const merchantCatalogProfile = createSelector(
  selectSelf,
  (state) => state.merchantCustomerData.merchantCatalogProfile,
)
export const selectMerchantCartItems = createSelector(
  selectSelf,
  (state) => state.merchantCustomerData.cart.items,
)
export const selectMerchantName = createSelector(
  selectSelf,
  (state) => state.merchantData.merchantProfile?.name || null,
)
export const selectMerchantProfile = createSelector(
  selectSelf,
  (state) => state.merchantData.merchantProfile,
)
