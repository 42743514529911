import { createSlice } from '@reduxjs/toolkit'
import * as authThunks from '@shopper/app/store/slices/auth/thunks'
import { type MerchantDataState } from '@shopper/app/store/slices/merchantData/types'
import * as REDUCER_NAMES from '@shopper/app/store/slices/names'
import loadMerchantData from './loadMerchantData'
import updateMerchantProfile from './updateMerchantProfile'

const initialState: MerchantDataState = {
  merchantProfile: null,
}

const REDUCER_NAME = REDUCER_NAMES.MERCHANT_DATA

const merchantDataSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(
    //   HYDRATE,
    //   (state): MerchantDataState => ({
    //     // No need to pass SSR userData state to client
    //     ...state,
    //   }),
    // )
    builder.addCase(loadMerchantData.fulfilled, (state, { payload }): MerchantDataState => {
      const { merchantProfile } = payload
      return {
        ...state,
        merchantProfile,
      }
    })
    builder.addCase(
      updateMerchantProfile.fulfilled,
      (state, { payload }): MerchantDataState => ({
        ...state,
        merchantProfile: payload.merchantProfile,
      }),
    )
    builder.addCase(
      authThunks.signOut.fulfilled,
      (state): MerchantDataState => ({
        ...state,
        merchantProfile: null,
      }),
    )
    builder.addCase(
      authThunks.signOut.rejected,
      (state): MerchantDataState => ({
        ...state,
        merchantProfile: null,
      }),
    )
  },
})

export const actions = merchantDataSlice.actions
export default merchantDataSlice
