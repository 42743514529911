import type { AppDispatch, RootState } from '@shopper/app/store'
import { actions } from '@shopper/app/store/slices/userData'
import isNil from 'lodash.isnil'
import 'react-native-get-random-values'
import { v4 as uuidv4 } from 'uuid'
import type { UserIdentifier } from '@centrito/api/nest/shopper/cart/domain/types/user-identifier'

export const getCartOwner = (state: RootState, dispatch: AppDispatch): UserIdentifier => {
  const anonymousId = state.userData.anonymousCartId
  const userId = state.userData.userProfile?.id
  const safeAnonymousId = anonymousId ?? uuidv4()

  if (isNil(anonymousId)) {
    dispatch(actions.setAnonymousCartId({ anonymousCartId: safeAnonymousId }))
  }

  return !isNil(userId)
    ? { userId, type: 'AUTHENTICATED' }
    : { anonymousId: safeAnonymousId, type: 'ANONYMOUS' }
}
