import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import trpcProxyClient from '@shopper/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@shopper/app/store'
import * as REDUCER_NAMES from '@shopper/app/store/slices/names'
import { loadAnonymousCart, loadUserData } from '@shopper/app/store/slices/userData/thunks'
import posthogClient from '@shopper/app/utils/services/analytics/posthog'
import isNil from 'lodash.isnil'
import type { UserProfilePublic } from '@centrito/api/nest/platform/database/domain'
import type { RegisterUserInput } from '@centrito/api/nest/shopper/user-profile/domain/schemas'

const signUp = createAsyncThunk<
  { userProfile: UserProfilePublic },
  RegisterUserInput,
  AppThunkApiConfig
>(`${REDUCER_NAMES.AUTH}/signUp`, async (payload, thunkAPI) => {
  try {
    const userProfile = await trpcProxyClient.auth.customer.signUp.mutate(payload)
    await thunkAPI.dispatch(loadUserData()).unwrap()
    if (!isNil(userProfile)) {
      await thunkAPI.dispatch(loadAnonymousCart()).unwrap()
    }
    posthogClient.captureCustomEvent('shopper_new_registration')
    return { userProfile }
  } catch (error) {
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})

export default signUp
