import { type PosthogClient } from '@shopper/app/utils/services/analytics/posthog'
import posthog from 'posthog-js'

export default function (
  this: PosthogClient,
  aliasId: string,
  isEnabled: boolean = this.analyticsEnabled,
): void {
  if (!isEnabled || !this.clientEnabled) return
  posthog.alias(aliasId)
}
