import { type TypedStartListening, createListenerMiddleware } from '@reduxjs/toolkit'
import type { AppDispatch, RootState } from '@shopper/app/store'
import { useSupabaseBrowserClient } from '@shopper/app/utils/supabase'
import isNil from 'lodash.isnil'
import { REHYDRATE } from 'redux-persist'

export const authRehydrateMiddleware = createListenerMiddleware()

// Define the typed version of startListening
type AppStartListening = TypedStartListening<RootState, AppDispatch>
;(authRehydrateMiddleware.startListening as AppStartListening)({
  type: REHYDRATE,
  effect: async (_, listenerApi) => {
    const state = listenerApi.getState()
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const supabaseClient = useSupabaseBrowserClient()
    if (isNil(state.auth?.authenticatedData)) {
      try {
        await supabaseClient.auth.signOut({ scope: 'local' })
      } catch (error) {
        console.error('Error signing out after rehydration:', error)
      }
    }
  },
})

export default authRehydrateMiddleware
