import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import { type AppThunkApiConfig } from '@shopper/app/store'
import * as REDUCER_NAMES from '@shopper/app/store/slices/names'
import { shopperNotificationsClient } from '@shopper/app/utils/notifications/shopper'
import posthogClient from '@shopper/app/utils/services/analytics/posthog'
import { type SupabaseClient } from '@supabase/supabase-js'

interface SignOutRequestPayload {
  supabaseClient: SupabaseClient
}

const signOut = createAsyncThunk<void, SignOutRequestPayload, AppThunkApiConfig>(
  `${REDUCER_NAMES.AUTH}/signOut`,
  async (payload, thunkAPI) => {
    try {
      if (shopperNotificationsClient.getIsRegistered()) {
        try {
          await shopperNotificationsClient.logout()
        } catch (error) {
          console.error('Error logging out from notifications:', error)
        }
      }

      const { supabaseClient } = payload
      const response = await supabaseClient.auth.signOut({ scope: 'local' })
      if (response.error) throw response.error

      posthogClient.resetIdentity()

      return
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)
export default signOut
