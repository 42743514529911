import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import trpcProxyClient from '@shopper/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@shopper/app/store'
import * as REDUCER_NAMES from '@shopper/app/store/slices/names'
import { getCartOwner } from '@shopper/app/store/slices/userData/utils/getCartOwner'
import type { CartPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites'

const removeCoupon = createAsyncThunk<
  {
    newCart: CartPublicComposite
  },
  undefined,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/removeCoupon`, async (_payload, thunkAPI) => {
  const currentState = thunkAPI.getState()
  const cartOwner = getCartOwner(currentState, thunkAPI.dispatch)

  try {
    const resp = await trpcProxyClient.user.cart.removeCoupon.mutate(cartOwner)
    return { newCart: resp.cart }
  } catch (e) {
    return thunkAPI.rejectWithValue(miniSerializeError(e))
  }
})
export default removeCoupon
