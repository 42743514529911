import {
  GovernmentIDType,
  LegalEntityType,
  OrderItemStateProcurement,
} from '@prisma/client/react-native'
import { $Enums } from '@centrito/db'

export const IMAGE_CODE_FROM_PNG_PIXEL_GRAY =
  'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mNkY2O1BQAAewBQL3RVZQAAAABJRU5ErkJggg=='
export const IMAGE_CODE_FROM_PNG_PIXEL_BLACK =
  'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAAEnQAABJ0Ad5mH3gAAAAMSURBVBhXY2BgYAAAAAQAAVzN/2kAAAAASUVORK5CYII='

export const BLUR_HASH =
  '|rF?hV%2WCj[ayj[a|j[az_NaeWBj@ayfRayfQfQM{M|azj[azf6fQfQfQIpWXofj[ayj[j[fQayWCoeoeaya}j[ayfQa{oLj?j[WVj[ayayj[fQoff7azayj[ayj[j[ayofayayayj[fQj[ayayj[ayfjj[j[ayjuayj['

export const MINIMUM_PICKUP_STORE_DISTANCE = 4

export const PICKUP_STORES_BOUNDING_BOX = [
  [4.85464192, -74.12680658],
  [4.81896689, -73.97646565],
  [4.4535029, -74.08061516],
  [4.5069775, -74.30746134],
]

export const REPLACE_CART_CALLED_LOCAL_STORAGE_KEY = 'replaceCartCalled'

export const ORDER_SUMMARY_BOX_TYPE = {
  STORE: 'STORE',
  ADDRESS: 'ADDRESS',
}

export const MERCHANT_GOVERMENT_ID_TYPE_OPTIONS = [
  { label: 'NIT', value: GovernmentIDType.COL_NIT },
  { label: 'RUT', value: GovernmentIDType.COL_RUT },
  { label: 'CC', value: GovernmentIDType.COL_CEDULA_CIUDADANIA },
  { label: 'CE', value: GovernmentIDType.COL_CEDULA_EXTRANJERIA },
]

export const SHOPPER_GOVERMENT_ID_TYPE_OPTIONS = [
  { label: 'CC', value: GovernmentIDType.COL_CEDULA_CIUDADANIA },
  { label: 'CE', value: GovernmentIDType.COL_CEDULA_EXTRANJERIA },
]

// Payments
export const DOCUMENT_TYPE_OPTIONS = [
  {
    label: 'Cédula de ciudadanía',
    value: GovernmentIDType.COL_CEDULA_CIUDADANIA,
  },
  {
    label: 'Cédula Extranjera',
    value: GovernmentIDType.COL_CEDULA_EXTRANJERIA,
  },
  {
    label: 'NIT',
    value: GovernmentIDType.COL_NIT,
  },
]

export const PERSON_TYPE_OPTIONS = [
  {
    label: 'Natural',
    value: LegalEntityType.NATURAL,
  },
  {
    label: 'Jurídica',
    value: LegalEntityType.LEGAL,
  },
]

export const REDIRECT_DELAY = 3000
export const REFETCH_MERCHANT_ORDER_ITEMS_INTERVAL = 1 * 60 * 1000 // 1 minute

type ORDER_STATE_TREE = {
  [key: string]: {
    text: string
    className: string
  }
}

const CLASS_NAME_INACTIVE = 'inactive'
const CLASS_NAME_IN_PROGRESS = 'in-progress'
const CLASS_NAME_IN_STORE = 'in-store'
const CLASS_NAME_COMPLETED = 'completed'
const CLASS_NAME_CANCELLED = 'cancelled'

export const ORDER_STATE_TREE: ORDER_STATE_TREE = {
  // Order state transitions
  [$Enums.OrderStateOrder.START_ACTIVE]: {
    text: 'Orden activa',
    className: CLASS_NAME_IN_PROGRESS,
  },
  [$Enums.OrderStateOrder.END_INACTIVE_COMPLETE]: {
    text: 'Orden inactiva',
    className: CLASS_NAME_INACTIVE,
  },
  [$Enums.OrderStateOrder.END_CANCELLED]: {
    text: 'Orden cancelada',
    className: CLASS_NAME_CANCELLED,
  },

  [$Enums.OrderViewStateDelivery.END_DELIVERED_SOME]: {
    text: 'Entrega parcial',
    className: CLASS_NAME_IN_PROGRESS,
  },
  [$Enums.OrderViewStateDelivery.END_DELIVERED_ALL]: {
    text: 'Entrega completa',
    className: CLASS_NAME_COMPLETED,
  },

  // Return state transitions
  [$Enums.OrderViewStateReturns.ACTIVE_IN_PROGRESS]: {
    text: 'Devolución en progreso',
    className: CLASS_NAME_IN_PROGRESS,
  },
  [$Enums.OrderViewStateReturns.END_RETURNED_ALL || $Enums.OrderViewStateReturns.END_RETURNED_SOME]:
    {
      text: 'Todos los artículos devueltos',
      className: CLASS_NAME_COMPLETED,
    },
  [$Enums.OrderViewStateReturns.END_RETURNED_FAILED]: {
    text: 'Devolución fallida',
    className: CLASS_NAME_CANCELLED,
  },

  // Delivery state cancellation reverse logistics transitions
  [$Enums.OrderDeliveryStateCancellationReverseLogistics.ACTIVE_AT_SOURCE]: {
    text: 'En origen',
    className: CLASS_NAME_IN_PROGRESS,
  },
  [$Enums.OrderDeliveryStateCancellationReverseLogistics.ACTIVE_EN_ROUTE]: {
    text: 'En ruta',
    className: CLASS_NAME_IN_PROGRESS,
  },
  [$Enums.OrderDeliveryStateCancellationReverseLogistics.END_COMPLETE]: {
    text: 'Cancelación completa',
    className: CLASS_NAME_COMPLETED,
  },

  // Delivery state logistics transitions
  [$Enums.OrderDeliveryStateLogistics.ACTIVE_COURIER_EN_ROUTE]: {
    text: 'Courier en ruta',
    className: CLASS_NAME_IN_PROGRESS,
  },
  [$Enums.OrderDeliveryStateLogistics.ACTIVE_AT_OFFICE_REPROGRAMMING]: {
    text: 'Reprogramación en oficina',
    className: CLASS_NAME_IN_PROGRESS,
  },
  [$Enums.OrderDeliveryStateLogistics.ACTIVE_NATIONAL_EN_ROUTE]: {
    text: 'En ruta nacional',
    className: CLASS_NAME_IN_PROGRESS,
  },
  [$Enums.OrderDeliveryStateLogistics.END_DELIVERED_CLIENT]: {
    text: 'Entregado al cliente',
    className: CLASS_NAME_COMPLETED,
  },
  [$Enums.OrderDeliveryStateLogistics.END_DELIVERED_STORE]: {
    text: 'Entregado en tienda',
    className: CLASS_NAME_IN_PROGRESS,
  },
  [$Enums.OrderDeliveryStateLogistics.END_CANCELLED_FAILED_JOURNEY]: {
    text: 'Cancelado - Viaje fallido',
    className: CLASS_NAME_CANCELLED,
  },
  [$Enums.OrderDeliveryStateLogistics.END_CANCELLED_NO_JOURNEY]: {
    text: 'Cancelado - Sin viaje',
    className: CLASS_NAME_CANCELLED,
  },

  // Delivery state packing transitions
  [$Enums.OrderDeliveryViewStatePacking.ACTIVE_PACKED_PARTIAL]: {
    text: 'Empaquetado parcial',
    className: CLASS_NAME_IN_PROGRESS,
  },
  [$Enums.OrderDeliveryViewStatePacking.END_PACKED_COMPLETE]: {
    text: 'Empaquetado completo',
    className: CLASS_NAME_COMPLETED,
  },
  [$Enums.OrderDeliveryViewStatePacking.END_NOT_PACKED_CANCELLED]: {
    text: 'No empaquetado - Cancelado',
    className: CLASS_NAME_CANCELLED,
  },

  // Delivery state store pickup transitions
  [$Enums.OrderDeliveryStateStorePickup.ACTIVE_ORDER_AT_STORE]: {
    text: 'Orden en tienda',
    className: CLASS_NAME_IN_STORE,
  },
  [$Enums.OrderDeliveryStateStorePickup.END_ORDER_PICKED_UP]: {
    text: 'Orden recolectada',
    className: CLASS_NAME_COMPLETED,
  },
  [$Enums.OrderDeliveryStateStorePickup.END_ORDER_MISSING_OR_STOLEN]: {
    text: 'Orden perdida o robada',
    className: CLASS_NAME_CANCELLED,
  },
  [$Enums.OrderDeliveryStateStorePickup.END_ORDER_CANCELLED__ABANDONED]: {
    text: 'Orden cancelada - Abandonada',
    className: CLASS_NAME_CANCELLED,
  },
  [$Enums.OrderDeliveryStateStorePickup.END_ORDER_CANCELLED__OTHER]: {
    text: 'Orden cancelada - Otra razón',
    className: CLASS_NAME_CANCELLED,
  },
}

const BACKGROUND_COLOR_PENDING = '#FF9400'
const BACKGROUND_COLOR_AVAILABLE = '#47BE85'
const BACKGROUND_COLOR_UNAVAILABLE = '#F9423A'

export const ORDER_ITEM_STATE_PREPURCHASE_TREE = {
  [OrderItemStateProcurement.START_PENDING]: {
    text: 'Pendiente',
    backgroundColor: BACKGROUND_COLOR_PENDING,
  },
  [OrderItemStateProcurement.END_ACQUIRED_VIA_MERCHANT__ORIGINAL]: {
    text: 'Disponible',
    backgroundColor: BACKGROUND_COLOR_AVAILABLE,
  },
  [OrderItemStateProcurement.END_ACQUIRED_VIA_MERCHANT__OTHER]: {
    text: 'Disponible',
    backgroundColor: BACKGROUND_COLOR_AVAILABLE,
  },
  [OrderItemStateProcurement.END_ACQUIRED_VIA_INVENTORY]: {
    text: 'Disponible',
    backgroundColor: BACKGROUND_COLOR_AVAILABLE,
  },
  [OrderItemStateProcurement.END_ACQUIRED_VIA_STOCK]: {
    text: 'Disponible',
    backgroundColor: BACKGROUND_COLOR_AVAILABLE,
  },
  [OrderItemStateProcurement.END_NOT_ACQUIRED__UNAVAILABLE]: {
    text: 'No disponible',
    backgroundColor: BACKGROUND_COLOR_UNAVAILABLE,
  },
  [OrderItemStateProcurement.END_NOT_ACQUIRED__CANCELLED]: {
    text: 'Cancelado',
    backgroundColor: BACKGROUND_COLOR_UNAVAILABLE,
  },
}

export const SEND_WHATSAPP_MESSAGE_URL = 'https://web.whatsapp.com/send?text='

const MAX_IMAGE_SIZE_BYTES = 1500000
export const MAX_IMAGE_SIZE_MB = MAX_IMAGE_SIZE_BYTES / 1000000
export const COLOMBIA_TIMEZONE = 'America/Bogota'
export const COLOMBIA_UTC_OFFSET = 5

export enum TOAST_TYPE {
  SUCCESS,
  WARNING,
  ERROR,
  OTHER,
}

export const BEAUTY_CATEGORY_NODE_PREFIX = 'BELLEZA'
export const DEFAULT_DEPARTMENT = 'CUNDINAMARCA'
export const BOGOTA = 'Bogotá'
export const SOACHA = 'Soacha'

export const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
]
export const CITY_NAME_BOGOTA = 'Bogotá'
export const CITY_NAME_SOACHA = 'Soacha'

export enum MoneyMovementType {
  OrderPayment = 'OrderPayment',
  UserPayoutRequest = 'UserPayoutRequest',
  UserCreditPayoutRequest = 'UserCreditPayoutRequest',
}

export enum AccountType {
  SAVINGS = 'savings',
  CHECKING = 'checking',
}

export const AccountTypeMap: Record<AccountType, string> = {
  savings: 'Ahorros',
  checking: 'Corriente',
}

export enum CouponsTabBarOptions {
  AVAILABLE = 'Disponibles',
  REDEEMED = 'Usados',
  EXPIRED = 'Expirados',
}

export const CouponStateOptions = [
  { displayName: 'Disponibles', type: CouponsTabBarOptions.AVAILABLE },
  { displayName: 'Usados', type: CouponsTabBarOptions.REDEEMED },
  { displayName: 'Expirados', type: CouponsTabBarOptions.EXPIRED },
]

export enum AddCouponType {
  ID = 'ID',
  CODE = 'CODE',
  USER_PROMOTION = 'USER_PROMOTION',
}

export const FREE_PRODUCTS_CATEGORY_ID = '1f473410-7c51-11ef-a3d9-9387177b0ee3'
export const MINIMUM_ORDER_TOTAL_NO_SHIPPING = 1000
export const MAX_CASH_ORDER_TOTAL = 200000

export const COUPON_BANNER_NAME = 'Cuponera'

export const CART_VALIDATION_ERROR_MESSAGE = {
  minimumOrderTotal: `El pedido mínimo es de $${MINIMUM_ORDER_TOTAL_NO_SHIPPING}`,
  freeProducts: {
    orderItem: 'No puedes comprar más de un producto gratis',
    cartItem: 'No puedes comprar más de un producto gratis',
  },
  alliedStore: {
    inactive: 'El punto Samsam seleccionado no está activo, por favor selecciona otro',
  },
}

export const PRICE_CHOP__CAMPAIGN_ID = '64b5610d-905e-4b0c-8b17-602b106ac5de'

export enum RedeemGameInviteResult {
  SUCCESS = 'SUCCESS',
  INVITATION_ALREADY_CONFIRMED = 'INVITATION_ALREADY_CONFIRMED',
  INVITATION_LIMIT_REACHED = 'INVITATION_LIMIT_REACHED',
  INVITATION_NOT_ALLOWED = 'INVITATION_NOT_ALLOWED',
}
