import React from 'react'
import { BaseButton } from '@shopper/ui/src/components/button/BaseButton'
import { type SizeTokens, Spinner, type ThemeValueFallback } from 'tamagui'

export interface BigMainButtonProps {
  onPress?: () => void
  width?: SizeTokens | ThemeValueFallback
  isGreen?: boolean
  isDisabled?: boolean
  isLoading?: boolean
  children: React.ReactNode | string
}

export const BigMainButton: React.FC<BigMainButtonProps> = ({
  children,
  onPress,
  width,
  isGreen = false,
  isDisabled = false,
  isLoading = false,
}) => {
  const _backgroundColor = isGreen ? '#F49428' : '#4646F5'

  return (
    <BaseButton
      fontFamily="$montserratFont"
      backgroundColor={isDisabled ? '#AAAAAA' : _backgroundColor}
      fontSize={15}
      fontWeight="700"
      color={'white'}
      icon={isLoading ? <Spinner /> : null}
      onPress={onPress}
      width={width}
      disabled={isDisabled}
      hasShadow={false}
    >
      {children}
    </BaseButton>
  )
}
