import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import trpcProxyClient from '@shopper/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@shopper/app/store'
import * as REDUCER_NAMES from '@shopper/app/store/slices/names'
import { actions } from '@shopper/app/store/slices/userData'
import { shopperNotificationsClient } from '@shopper/app/utils/notifications/shopper'
import isEmpty from 'lodash.isempty'
import isNil from 'lodash.isnil'
import type {
  AlliedStorePublic,
  UserProfilePublic,
} from '@centrito/api/nest/platform/database/domain'

export interface LoadUserDataResponse {
  selectedStore: AlliedStorePublic | null
  userProfile: UserProfilePublic
}

const loadUserData = createAsyncThunk<LoadUserDataResponse, void, AppThunkApiConfig>(
  `${REDUCER_NAMES.USER_DATA}/loadUserData`,
  async (_, thunkAPI) => {
    try {
      const { userProfile, store: selectedStore } = await trpcProxyClient.user.profile.get.query()

      if (shopperNotificationsClient.getIsRegistered()) {
        const { firstName, gender, id, age, email } = userProfile

        await shopperNotificationsClient.login(id)

        const capitalized = firstName
          .split(/\s+/)
          .map((name) => name.toLowerCase().replace(/^./, (str) => str.toUpperCase()))
          .join(' ')

        const tags = {
          first_name: capitalized,
          ...(!isNil(gender) && { gender: gender[0].toUpperCase() }),
          ...(!isNil(age) && { age }),
          ...(!isNil(email) && { email }),
        }

        await shopperNotificationsClient.addTags(tags)

        if (!isNil(email) && !isEmpty(email)) {
          await shopperNotificationsClient.registerEmail({ email })
          thunkAPI.dispatch(actions.setIsEmailNotificationsEnabled({ isEnabled: true }))
        }
      }

      return {
        userProfile,
        selectedStore,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)
export default loadUserData
