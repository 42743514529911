import React from 'react'
import useAuthListener from '@shopper/app/utils/hooks/useAuthListener'

const AuthProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  useAuthListener()
  return children ? <>{children}</> : null
}

export default AuthProvider
