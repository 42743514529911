import React, { Fragment, useEffect } from 'react'
import { useRouter } from 'next/router'
import CenterSpinLoading from '@shopper/app/components/CenterSpinLoading'
import PATHS from '@centrito/common/paths'

interface MidRouteProps {
  redirect: string
  children: React.ReactNode
}

const MidRoute: React.FC<MidRouteProps> = ({ redirect, children }: MidRouteProps) => {
  const router = useRouter()
  useEffect(() => {
    if (redirect) router.push(redirect)
  }, [redirect, router])
  return redirect ? <CenterSpinLoading /> : <>{children}</>
}

interface PublicRouteProps {
  children: React.ReactNode
  redirect: string
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children, redirect }: PublicRouteProps) => {
  return <MidRoute redirect={redirect}>{children}</MidRoute>
}

const getGroupRoute = (
  path: { [key: string]: string },
  route: React.ComponentType<MidRouteProps>,
  layout: React.ComponentType<{ children: React.ReactNode }>,
): object =>
  Object.values(path).reduce(
    (acc, key) => ({
      ...acc,
      [key]: {
        Route: route,
        Layout: layout,
      },
    }),
    {},
  )

type Routes = {
  [key: string]: {
    Route: React.ComponentType<MidRouteProps>
    Layout: React.ComponentType<{ children: React.ReactNode }>
  }
}

const ROUTES: Routes = {
  ...getGroupRoute(PATHS.Site, PublicRoute, Fragment),
}

const Route = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const router = useRouter()
  const RouteParts = ROUTES[router.pathname] || {}
  const RouteComponent = RouteParts.Route || (({ children }): JSX.Element => <>{children}</>)
  const LayoutComponent = RouteParts.Layout || (({ children }): JSX.Element => <>{children}</>)

  return (
    <RouteComponent redirect="">
      <LayoutComponent>{children}</LayoutComponent>
    </RouteComponent>
  )
}

export default Route
