import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import trpcProxyClient from '@shopper/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@shopper/app/store'
import * as REDUCER_NAMES from '@shopper/app/store/slices/names'
import type { CartPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites'
import type { CheckAndRedeemFreeProductInput } from '@centrito/api/router/promotions/priceChop/checkAndRedeemFreeProduct'

const checkAndRedeemFreeProduct = createAsyncThunk<
  {
    newCart: CartPublicComposite
  },
  CheckAndRedeemFreeProductInput,
  AppThunkApiConfig
>(
  `${REDUCER_NAMES.USER_DATA}/checkAndRedeemFreeProduct`,
  async (payload: CheckAndRedeemFreeProductInput, thunkAPI) => {
    try {
      const result =
        await trpcProxyClient.promotions.priceChop.checkAndRedeemFreeProduct.mutate(payload)
      return result
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)

export default checkAndRedeemFreeProduct
