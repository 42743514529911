import initialState from '@shopper/app/store/slices/userData/initialState'
import { UserDataState } from '@shopper/app/store/slices/userData/types'
import isNil from 'lodash.isnil'
import type { z } from 'zod'
import { migrateFromZero } from './0'
import { migrateFromOne } from './1'
import { migrateFromNegativeOne } from './-1'

const migrations: Array<{ version: number; migrate: (state: any) => any }> = [
  { version: -1, migrate: migrateFromNegativeOne },
  { version: 0, migrate: migrateFromZero },
  { version: 1, migrate: migrateFromOne },
]

const getParsedUserData = (state: any): any => {
  return (Object.keys(UserDataState.shape) as Array<keyof z.infer<typeof UserDataState>>).reduce(
    (acc, key) => {
      const subSchema = UserDataState.shape[key]
      const subParsed = subSchema.safeParse(state.userData[key])

      // Use the parsed data if successful, otherwise fallback to initialState
      acc[key] = subParsed.success ? subParsed.data : initialState[key]

      return acc
    },
    {} as z.infer<typeof UserDataState>,
  )
}

export const migrateState = (state: any): any => {
  if (isNil(state)) {
    return state
  }

  const currentVersion = state._persist?.version ?? -1

  // Apply all migrations that are newer than the current version
  const migratedState = migrations
    .filter(({ version }) => currentVersion <= version)
    .reduce((acc, { migrate }) => migrate(acc), state)

  // Always validate schema at the end
  const parsedUserData = getParsedUserData(migratedState)
  return {
    ...migratedState,
    userData: parsedUserData,
  }
}
