import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import { IS_MERCHANT_APP } from '@shopper/app/config'
import type { AppThunkApiConfig } from '@shopper/app/store'
import loadMerchantData from '@shopper/app/store/slices/merchantData/loadMerchantData'
import * as REDUCER_NAMES from '@shopper/app/store/slices/names'
import { loadAnonymousCart, loadUserData } from '@shopper/app/store/slices/userData/thunks'
import type { SupabaseClient } from '@supabase/supabase-js'
import isNil from 'lodash.isnil'
import type { SupabaseUser } from '@centrito/api/nest/platform/auth/domain/schemas'
import signOut from './signOut'

interface LoadAuthStateChangeRequestPayload {
  user: SupabaseUser | null
  supabaseClient: SupabaseClient
  initialAuthState?: boolean
}

interface LoadAuthStateChangeResponsePayload {
  user: SupabaseUser
}

const loadAuthStateChange = createAsyncThunk<
  LoadAuthStateChangeResponsePayload,
  LoadAuthStateChangeRequestPayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.AUTH}/loadAuthStateChange`, async (payload, thunkAPI) => {
  try {
    const { user, supabaseClient } = payload

    if (!isNil(user)) {
      const isMerchantPortal = IS_MERCHANT_APP
      const isMerchant = isMerchantPortal && user.user_metadata.role.includes('MERCHANT')
      const isRegistered = isMerchant
        ? user.user_metadata.isRegistered.merchant
        : user.user_metadata.isRegistered.shopper
      const isCustomer = !isMerchant

      if (isCustomer && isRegistered) {
        await thunkAPI.dispatch(loadUserData()).unwrap()
        await thunkAPI.dispatch(loadAnonymousCart()).unwrap()
      } else if (isMerchant) {
        thunkAPI.dispatch(loadMerchantData())
      } else if (!isCustomer && !isMerchant) {
        thunkAPI.dispatch(signOut({ supabaseClient }))
        throw new Error('User has invalid values')
      }

      return {
        user: {
          ...user,
          user_metadata: user.user_metadata,
        },
      }
    } else {
      throw new Error('User not authenticated')
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})
export default loadAuthStateChange
