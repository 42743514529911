import { useEffect, useState } from 'react'
import { isServer } from '@shopper/app/config'
import { useDispatch, useSelector } from '@shopper/app/store'
import {
  selectAuthLoaded,
  selectAuthenticatedData,
  selectIsAuthenticated,
} from '@shopper/app/store/selectors'
import { loadAuthStateChange } from '@shopper/app/store/slices/auth/thunks'
import { getUserCart } from '@shopper/app/store/slices/userData/thunks'
import { getIsAuthChange } from '@shopper/app/utils/auth'
import { useSupabaseBrowserClient } from '@shopper/app/utils/supabase'
import { type Session, useUser } from '@supabase/auth-helpers-react'
import isNil from 'lodash.isnil'
import type { SupabaseUser } from '@centrito/api/nest/platform/auth/domain/schemas'

const useAuthListener = (): void => {
  const dispatch = useDispatch()
  const [isChangingAuthState, setIsChangingAuthState] = useState(false)
  const [eventQueue, setEventQueue] = useState<(Session | null)[]>([])
  const authLoaded = useSelector(selectAuthLoaded)
  const inAuthSession = useSelector(selectIsAuthenticated)
  const authenticatedData = useSelector(selectAuthenticatedData)
  const supabaseClient = useSupabaseBrowserClient()
  const user = useUser() as SupabaseUser | null

  useEffect(() => {
    if (!isServer && !authLoaded && isNil(authenticatedData)) {
      dispatch(loadAuthStateChange({ user, supabaseClient, initialAuthState: true }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const processEvent = async (session: Session | null): Promise<void> => {
    setIsChangingAuthState(true)
    try {
      const authChangeUser = (session?.user as SupabaseUser | undefined) ?? null
      const isAuthChange = getIsAuthChange({
        authChangeUser,
        hasStateInAuthSession: inAuthSession,
        stateAuthenticatedData: authenticatedData,
      })

      if (isAuthChange) {
        await dispatch(loadAuthStateChange({ user: authChangeUser, supabaseClient }))
      }
    } finally {
      setIsChangingAuthState(false)
    }
  }

  useEffect(() => {
    if (!isServer) {
      dispatch(getUserCart())
      const {
        data: { subscription },
      } = supabaseClient.auth.onAuthStateChange((_, session: Session | null) => {
        setEventQueue((prevQueue) => [...prevQueue, session])
      })
      return (): void => {
        if (subscription) subscription.unsubscribe()
      }
    }
    return undefined
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isChangingAuthState && eventQueue.length > 0) {
      processEvent(eventQueue[0])
      setEventQueue((prevQueue) => prevQueue.slice(1))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventQueue, isChangingAuthState])
}

export default useAuthListener
