import { isProd, isServer } from '@shopper/app/config'
import { isClient } from '@shopper/ui/src'

export const ANALYTICS_ENABLED = isProd && (!isServer || isClient)
export const META_ENABLED = true as boolean
export const validEventNames = [
  'shopper_order_created',
  'shopper_update_cart_items',
  'shopper_delivery_type',
  'shopper_pageview_product',
  'shopper_pageview_checkout_cart',
  'shopper_pageview_checkout_single',
  'shopper_pageview_checkout_domicile',
  'shopper_checkout_domicile_updated',
  'shopper_checkout_allied_store_updated',
  'shopper_pageview_checkout_allied-store',
  'shopper_pageview_checkout_payment_nequi',
  'shopper_pageview_checkout_payment_nequi_notification',
  'shopper_pageview_checkout_payment_nequi_nopayment',
  'shopper_pageview_checkout_payment_belvo',
  'shopper_pageview_checkout_payment_belvo_success',
  'shopper_pageview_checkout_payment_belvo_error',
  'shopper_pageview_checkout_login',
  'shopper_pageview_checkout_register',
]
