import type { CartItemStatus } from '@prisma/client/react-native'
import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import trpcProxyClient from '@shopper/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@shopper/app/store'
import * as REDUCER_NAMES from '@shopper/app/store/slices/names'
import { getCartOwner } from '@shopper/app/store/slices/userData/utils/getCartOwner'
import type { CartPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites'
import { CartValidationError } from '@centrito/api/nest/shopper/cart/domain/errors'

export type UpdateCartItemStatusResponse =
  | {
      newCart: CartPublicComposite
    }
  | {
      cartValidationError: string
    }

export type UpdateCartItemStatusPayload = {
  productId: string
  variantId: string
  sizeName: string
  status: CartItemStatus
}

const updateCartItemStatus = createAsyncThunk<
  UpdateCartItemStatusResponse,
  UpdateCartItemStatusPayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/updateCartItemStatus`, async (payload, thunkAPI) => {
  const { status, productId, variantId, sizeName } = payload

  try {
    const currentState = thunkAPI.getState()
    const cartOwner = getCartOwner(currentState, thunkAPI.dispatch)

    try {
      const { newCart } = await trpcProxyClient.user.cart.updateCartItemStatus.mutate({
        cartOwner,
        status,
        targetItem: {
          productId,
          productVariantId: variantId,
          sizeName,
        },
      })

      return { newCart }
    } catch (error) {
      if (error instanceof CartValidationError) {
        return { cartValidationError: error.message }
      }
      throw error
    }
  } catch (error) {
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})
export default updateCartItemStatus
