import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from 'next/router'
import posthogClient from '@shopper/app/utils/services/analytics/posthog'
import NProgress from 'nprogress'

export interface RouteContextInterface {
  isRouting: boolean
  historyStack: string[]
  canGoBack: boolean
}

const initialContext: RouteContextInterface = {
  isRouting: false,
  historyStack: [],
  canGoBack: false,
}

export const RouteContext = createContext<RouteContextInterface>(initialContext)

const RouteListener = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const router = useRouter()
  const scrollPositions = useRef<{ [url: string]: { [k: string]: number } }>({})
  const isBack = useRef(false)
  const [isRouting, setIsRouting] = useState(false)
  const [historyStack, setHistoryStack] = useState<string[]>([router.asPath])
  const CONTAINERS = ['catalog-content']

  useEffect(() => {
    const elements: { [k: string]: Element | null } = CONTAINERS.reduce(
      (acc, k) => ({ [k]: document.querySelector(`.${k}`), ...acc }),
      {},
    )
    let isBackNavigation = false

    router.beforePopState(() => {
      isBack.current = true
      isBackNavigation = true

      if (historyStack.length === 0) {
        router.push('/')
        setHistoryStack(['/'])
        isBackNavigation = true
        return false
      }

      setHistoryStack((prevStack) => prevStack.slice(0, -1))
      isBackNavigation = true
      return true
    })

    const handleStart = (): void => {
      setIsRouting(true)
      NProgress.start()
      const url = router.asPath
      // Scroll Logic
      scrollPositions.current[url] = {
        window: window.scrollY,
        ...Object.fromEntries(
          Object.entries(elements)
            .filter(([, el]) => el !== undefined)
            .map(([k, el]) => [k, el?.scrollTop] as [string, number]),
        ),
      }
    }
    const handleFulfill = (url: any): void => {
      posthogClient.capturePageView()
      setIsRouting(false)
      NProgress.done()

      // Scroll Logic
      if (isBack.current && scrollPositions.current[url] && !url.startsWith('/products/p')) {
        window.scroll({
          top: scrollPositions.current[url].window,
          behavior: 'auto',
        })
        Object.entries(scrollPositions.current[url])
          .filter(([k]) => k !== 'window')
          .forEach(([k, scrollPosition]) => {
            const el = elements[k]
            if (el?.scrollHeight && el.scrollHeight > scrollPosition) {
              el?.scroll({
                top: scrollPosition,
                behavior: 'auto',
              })
            }
          })
      } else if (url.startsWith('/products/p')) {
        // Reset scroll position to top for forward navigation
        window.scrollTo(0, 0)
        Object.values(elements).forEach((el) => {
          if (el) el.scrollTop = 0
        })
      }

      if (isBackNavigation) {
        // Back navigation occurred; do not push the URL onto the stack
        isBackNavigation = false
      } else {
        setHistoryStack((prevStack) => {
          if (prevStack[prevStack.length - 1] !== url) {
            return [...prevStack, url]
          }
          return prevStack
        })
      }
    }
    const handleStop = (): void => {
      setIsRouting(false)
      NProgress.done()
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleFulfill)
    router.events.on('routeChangeError', handleStop)

    return (): void => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleFulfill)
      router.events.off('routeChangeError', handleStop)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  const canGoBack = historyStack.length > 1

  const memoizedIsRouting = useMemo(() => {
    return {
      isRouting,
      historyStack,
      canGoBack,
    }
  }, [isRouting, historyStack, canGoBack])

  return <RouteContext.Provider value={memoizedIsRouting}>{children}</RouteContext.Provider>
}

export const useHistory = (): RouteContextInterface => useContext(RouteContext)

export default RouteListener
