export const SitePrefix = '/site'
export const CatalogPrefix = '/catalog'
export const ProductPrefix = '/products'
export const AuthPrefix = '/auth'
export const CheckoutPrefix = '/checkout'
export const AdminPrefix = '/admin'
export const AccountPrefix = '/account'
export const WalletPrefix = `${AccountPrefix}/wallet`
export const OrderPrefix = '/order'
export const LegalPrefix = '/legal'
export const Search = '/search'
export const AlliedStoresContent = '/allied-stores'
export const PaymentsPrefix = `${CheckoutPrefix}/payment`
export const ReviewPrefix = '/reviews'
export const PriceChopPrefix = '/price-chop'

// TODO: rewrite paths to Spanish
// TODO: not only rewrite paths, but also SEO could be affected and we have to do some redirects
const PATHS = {
  Site: {
    Home: '/home/0',
    MobileHome: '/home/[tab]',
    Categories: '/categories',
    BasePath: '/',
  } as const,
  AlliedStores: {
    Index: `${AlliedStoresContent}`,
    City: `${AlliedStoresContent}/city/[cityName]`,
  } as const,
  Legal: {
    TermsOfService: `${LegalPrefix}/terms-of-service`,
    Privacy: `${LegalPrefix}/privacy`,
    Returns: `${LegalPrefix}/returns`,
    BusinessModel: `${LegalPrefix}/business-model`,
  } as const,
  Search: {
    Main: `${Search}`,
    Text: `${Search}/[searchTerm]`,
  } as const,
  Products: {
    Product: `${ProductPrefix}/p/[productId]/[variantIndex]`,
    List: `${ProductPrefix}/l/[listId]`,
    ListByCategoryPrefix: `${ProductPrefix}/n/[categoryNodesPrefix]`,
    ListByBrand: `${ProductPrefix}/b/[brandId]`,
    ListByMerchant: `${ProductPrefix}/m/[merchantId]`,
    ListAll: `${ProductPrefix}`,
    Reviews: `${ProductPrefix}/r/[productId]`,
    Outlets: `${ProductPrefix}/outlets`,
  } as const,
  PriceChop: {
    CodeLogin: `${PriceChopPrefix}/code/login`,
    CodeRegister: `${PriceChopPrefix}/code/register/[code]`,
    Code: `${PriceChopPrefix}/code/[code]`,
    Products: `${PriceChopPrefix}/products`,
    Share: `${PriceChopPrefix}/share`,
    ConfirmProduct: `${PriceChopPrefix}/confirm-product/[productId]/[variantIndex]`,
    ConfirmProductLogin: `${PriceChopPrefix}/confirm-product/login`,
    InvitationFailed: `${PriceChopPrefix}/invitation-failed`,
    InvitationAccepted: `${PriceChopPrefix}/invitation-accepted`,
    Success: `${PriceChopPrefix}/success`,
    DeviceNotAllowed: `${PriceChopPrefix}/device-not-allowed`,
    Register: `${PriceChopPrefix}/register`,
  } as const,
  Auth: {
    Register: `${AuthPrefix}/register`,
    Login: `${AuthPrefix}/login`,
  } as const,
  Checkout: {
    OrderCart: `${CheckoutPrefix}/cart`,
    MobileCart: '/cart',
    Register: `${CheckoutPrefix}/register`,
    Login: `${CheckoutPrefix}/login`,
    SalesAssistantFindUser: `${CheckoutPrefix}/find-user`,
    Address: {
      AlliedStore: `${CheckoutPrefix}/address/allied-store`,
      Domicile: `${CheckoutPrefix}/address/domicile/[city]`,
      NationalShipping: `${CheckoutPrefix}/address/national-shipping`,
      Confirm: `${CheckoutPrefix}/address/confirm`,
      Current: `${CheckoutPrefix}/address/current`,
    } as const,
    Single: `${CheckoutPrefix}/single`,
    Coupons: `${CheckoutPrefix}/coupons`,
    Payment: {
      Palomma: {
        Index: `${PaymentsPrefix}/palomma`,
      } as const,
    } as const,
  } as const,
  Account: {
    Coupons: `${AccountPrefix}/coupons`,
    Orders: `${AccountPrefix}/orders`,
    OrderDetail: `${AccountPrefix}/orders/[id]`,
    OrderTracking: `${AccountPrefix}/orders/tracking/[id]`,
    Profile: `${AccountPrefix}/profile`,
    MobileProfile: '/profile',
    Notifications: `${AccountPrefix}/notifications`,
    Wallet: {
      Index: `${WalletPrefix}`,
      Withdrawals: `${WalletPrefix}/withdrawals`,
    } as const,
  } as const,
  Order: {
    AlternativesList: `${OrderPrefix}/alternatives/[orderId]`,
    AlternativesSelect: `${OrderPrefix}/alternatives/[orderId]/[orderItemId]`,
    OrderDetail: `${OrderPrefix}`,
  } as const,
  Reviews: {
    OrderReviews: `${AccountPrefix}${ReviewPrefix}`,
    Order: `${AccountPrefix}${ReviewPrefix}/[orderId]`,
    OrderItems: `${AccountPrefix}${ReviewPrefix}/l/[orderId]`,
    OrderItem: `${AccountPrefix}${ReviewPrefix}/l/[orderId]/[orderItemId]`,
    ReviewDetail: `${AccountPrefix}${ReviewPrefix}/r/[productReviewId]`,
  } as const,
  ShortLink: '/l/[shortLinkId]',
}

export const PATHS_ALLIED_STORES = {
  HOME: '/',
  AUTH: {
    LOGIN: '/auth/iniciar-sesion',
    REGISTER: '/auth/registrarse',
  },
  REPORTS: '/reportes',
  ORDERS: {
    HOME: '/pedidos',
    RECEIVE: '/pedidos/recibir',
    DELIVER: '/pedidos/entregar',
    RETURN: '/pedidos/devolver',
  },
  PAY_COLLECTION: '/pagar-recaudo',
  HELP: '/ayuda',
  TERMS_AND_CONDITIONS: '/terminos-y-condiciones',
  PURCHASES: {
    HOME: '/compras',
    CATALOG: {
      HOME: '/compras/catalogo',
      MERCHANT: '/compras/catalogo/proveedor/[merchantId]',
      PRODUCT: '/compras/catalogo/proveedor/[merchantId]/producto/[productId]',
    },
  },
} as const

export default PATHS
