import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import type { AppThunkApiConfig } from '@shopper/app/store'
import * as REDUCER_NAMES from '@shopper/app/store/slices/names'
import isNil from 'lodash.isnil'
import type { MerchantPublic } from '@centrito/api/nest/platform/database/domain'

export interface LoadMerchantCatalogDataResponse {
  merchant: MerchantPublic | null
}

export interface LoadMerchantCatalogDataProps {
  merchant: MerchantPublic
}

export default createAsyncThunk<
  LoadMerchantCatalogDataResponse,
  LoadMerchantCatalogDataProps,
  AppThunkApiConfig
>(
  `${REDUCER_NAMES.MERCHANT_CUSTOMER_DATA}/loadMerchantCatalogData`,
  async ({ merchant }, thunkAPI) => {
    try {
      if (isNil(merchant)) {
        throw new Error('Merchant profile not found')
      }
      return {
        merchant,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)
