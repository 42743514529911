import React from 'react'
import type { AppProps } from 'next/app'
import config from '@shopper/app/tamagui.config'
import { TamaguiProvider, type TamaguiProviderProps } from '@shopper/ui/src'
import { NextThemeProvider, useRootTheme } from '@tamagui/next-theme'
import { useColorScheme } from 'react-native'

const ThemeProviderInner: React.FC<AppProps & Omit<TamaguiProviderProps, 'config'>> = ({
  children,
  Component,
  ...rest
}) => {
  const scheme = useColorScheme()
  return (
    <TamaguiProvider
      config={config}
      disableInjectCSS
      defaultTheme={scheme === 'dark' ? 'dark' : 'light'}
      {...rest}
    >
      {children}
    </TamaguiProvider>
  )
}

const ThemeProvider: React.FC<AppProps> = (appProps) => {
  const [theme, setTheme] = useRootTheme()
  return (
    <NextThemeProvider
      onChangeTheme={(themeName) => setTheme(themeName === 'dark' ? 'dark' : 'light')}
      forcedTheme={'light'}
    >
      <ThemeProviderInner disableRootThemeClass defaultTheme={theme} {...appProps} />
    </NextThemeProvider>
  )
}

export default ThemeProvider
