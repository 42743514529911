import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import trpcProxyClient from '@shopper/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@shopper/app/store'
import * as REDUCER_NAMES from '@shopper/app/store/slices/names'
import type { CreateOrderPayload } from '@shopper/app/utils/api/types'
import type { OrderExtendedPublicComposite } from '@centrito/api/nest/shopper/orders/domain/composites/order-extended.composite'

const createOrder = createAsyncThunk<
  { newOrder: OrderExtendedPublicComposite } | undefined,
  CreateOrderPayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/createOrder`, async (payload, thunkAPI) => {
  try {
    return await trpcProxyClient.user.order.create.mutate(payload)
  } catch (error) {
    console.warn(error)
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})

export default createOrder
