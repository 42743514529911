import React from 'react'
import { darkenColor } from '@shopper/ui/src/utils'
import { Link } from 'solito/link'
import type { ButtonProps, TamaguiElement } from 'tamagui'
import { Button, YStack, isClient } from 'tamagui'
import type { UrlObject } from 'url'

export interface BaseButtonProps extends ButtonProps {
  linkProps?: UrlObject | string
  isFullWidth?: boolean
  flex?: number
  iconAfter?: JSX.Element | React.ReactElement
  hasShadow?: boolean
  children: React.ReactNode
}

// eslint-disable-next-line react/display-name
export const BaseButton = React.forwardRef<TamaguiElement, BaseButtonProps>(
  (
    {
      children,
      backgroundColor = '#e9e9ea',
      color = '#000000',
      onPress,
      themeInverse = false,
      size = 48,
      width,
      height = 48,
      fontFamily = '$poppinsFont',
      marginHorizontal = 0,
      fontSize = isClient ? 16 : 14,
      fontWeight = '400',
      isFullWidth = false,
      borderRadius,
      borderColor,
      borderWidth,
      linkProps = undefined,
      disabled = false,
      hasShadow = true,
      iconAfter = undefined,
      icon = undefined,
      justifyContent = 'center',
      padding = undefined,
      space = undefined,
      paddingVertical = undefined,
      pressStyle = undefined,
      focusStyle = undefined,
      hoverStyle = undefined,
      flex = undefined,
    },
    ref,
  ) => {
    const stringifiedBackgroundColor = backgroundColor.toString()
    const stringifiedBorderColor = borderColor?.toString()
    const button = (
      <Button
        justifyContent={justifyContent}
        fontFamily={fontFamily}
        backgroundColor={backgroundColor}
        color={color}
        size={size}
        height={height}
        fontSize={fontSize}
        fontWeight={fontWeight}
        pressStyle={
          pressStyle
            ? { ...pressStyle }
            : {
                backgroundColor: darkenColor(stringifiedBackgroundColor),
                borderColor: stringifiedBorderColor
                  ? darkenColor(stringifiedBorderColor)
                  : borderColor,
                outlineStyle: 'none',
              }
        }
        onPress={onPress}
        hoverStyle={
          hoverStyle
            ? { ...hoverStyle }
            : {
                backgroundColor: darkenColor(stringifiedBackgroundColor),
                borderColor: stringifiedBorderColor
                  ? darkenColor(stringifiedBorderColor)
                  : borderColor,
                outlineStyle: 'none',
              }
        }
        focusStyle={
          focusStyle
            ? { ...focusStyle }
            : {
                backgroundColor: darkenColor(stringifiedBackgroundColor),
                borderColor: stringifiedBorderColor
                  ? darkenColor(stringifiedBorderColor)
                  : borderColor,
                outlineStyle: 'none',
              }
        }
        themeInverse={themeInverse}
        borderRadius={borderRadius}
        borderColor={borderColor}
        borderWidth={borderWidth}
        disabled={disabled}
        iconAfter={iconAfter}
        icon={icon}
        {...(hasShadow
          ? {
              elevation: 10,
              shadowOffset: { width: 0, height: 4 },
              shadowRadius: 18,
              shadowColor: '#0000001E',
            }
          : {})}
        ref={ref}
        alignItems="center"
        padding={padding}
        space={space}
        paddingVertical={paddingVertical}
      >
        {children}
      </Button>
    )

    return (
      <YStack width={isFullWidth ? '100%' : width} flex={flex} marginHorizontal={marginHorizontal}>
        {linkProps ? <Link href={linkProps}>{button}</Link> : button}
      </YStack>
    )
  },
)
