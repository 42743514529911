import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import trpcProxyClient from '@shopper/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@shopper/app/store'
import * as REDUCER_NAMES from '@shopper/app/store/slices/names'
import type { CartPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites'

export interface LoadAnonymousCartResponse {
  cart: CartPublicComposite
}

const loadAnonymousCart = createAsyncThunk<LoadAnonymousCartResponse, void, AppThunkApiConfig>(
  `${REDUCER_NAMES.USER_DATA}/loadAnonymousCart`,
  async (_, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState()
      const anonymousCartId = currentState.userData.anonymousCartId
      const { newCart } = await trpcProxyClient.user.cart.replaceAnonymousCart.mutate({
        anonymousCartId: anonymousCartId!,
      })
      return {
        cart: newCart,
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)
export default loadAnonymousCart
