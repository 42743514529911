import React from 'react'
import { Stack, XStack, YStack } from '@shopper/ui/src'

const Left: React.FC<{
  children: React.ReactNode
}> = ({ children }) => (
  <XStack maxWidth="100%" justifyContent="flex-start" alignItems="center">
    {children}
  </XStack>
)
const Right: React.FC<{
  children: React.ReactNode
}> = ({ children }) => (
  <XStack justifyContent="flex-end" alignItems="center" space>
    {children}
  </XStack>
)
const Center: React.FC<{
  children: React.ReactNode
}> = ({ children }) => (
  <XStack
    flexGrow={1}
    flexShrink={1}
    flexBasis="auto"
    justifyContent="center"
    alignItems="center"
    space
  >
    {children}
  </XStack>
)

const Bottom: React.FC<{
  children: React.ReactNode
}> = ({ children }) => (
  <YStack alignItems="stretch" alignContent="stretch" justifyContent="center" width="100%">
    {children}
  </YStack>
)

interface NavBarProps {
  isPaddingTopEnabled?: boolean
  isPaddingLeftEnabled?: boolean
  isBackgroundTransparent?: boolean
  isBottomLineEnabled?: boolean
  isProductPage?: boolean
  marginTop?: number
  absoluteCenter?: boolean
  children: React.ReactNode
}

const NavBar: React.FC<NavBarProps> & {
  Left: typeof Left
  Right: typeof Right
  Center: typeof Center
  Bottom: typeof Bottom
} = ({
  children,
  isPaddingTopEnabled = false,
  isPaddingLeftEnabled = false,
  isBackgroundTransparent = false,
  isBottomLineEnabled = false,
  isProductPage = false,
  absoluteCenter = true,
  marginTop = 0,
}) => {
  // Create arrays for storing different types of children
  const leftChildren: React.ReactNode[] = []
  const centerChildren: React.ReactNode[] = []
  const rightChildren: React.ReactNode[] = []
  const bottomChildren: React.ReactNode[] = []

  // Separate children by their type
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      switch (child.type) {
        case Left:
          leftChildren.push(child)
          break
        case Center:
          centerChildren.push(child)
          break
        case Right:
          rightChildren.push(child)
          break
        case Bottom:
          bottomChildren.push(child)
          break
        default:
          break
      }
    }
  })

  return (
    <YStack
      padding={0}
      paddingBottom={7.5}
      paddingTop={isPaddingTopEnabled ? 7.5 : 0}
      width="100%"
      backgroundColor={isBackgroundTransparent ? 'transparent' : '#FFFFFF'}
      zIndex={99}
      elevation={isBackgroundTransparent ? 0 : isBottomLineEnabled ? 1 : 10}
      shadowOffset={isBottomLineEnabled ? { width: 0, height: 0 } : { width: 0, height: 4 }}
      shadowRadius={5}
      position={isProductPage ? 'absolute' : 'relative'}
      shadowColor={isBackgroundTransparent ? '#FFFFFF00' : '#0000001E'}
      paddingLeft={isPaddingLeftEnabled ? 16 : 0}
      paddingRight={rightChildren.length > 0 ? 16 : 0}
      top={marginTop}
    >
      <XStack
        display="flex"
        flexDirection="row"
        alignItems="center"
        height={30}
        marginVertical={0}
        width="100%"
        justifyContent="space-between"
      >
        <XStack flex={1} justifyContent="flex-start" alignItems="center" zIndex={2}>
          {leftChildren}
        </XStack>
        {absoluteCenter ? (
          <Stack
            position="absolute"
            left={0}
            right={0}
            top={0}
            bottom={0}
            justifyContent="center"
            alignItems="center"
          >
            {centerChildren}
          </Stack>
        ) : (
          <XStack flex={8} justifyContent="center" alignItems="center">
            {centerChildren}
          </XStack>
        )}
        <XStack flex={1} justifyContent="flex-end" gap={5} paddingRight={5} zIndex={2}>
          {rightChildren}
        </XStack>
      </XStack>
      <YStack alignItems="stretch" width="100%" alignContent="stretch">
        {bottomChildren}
      </YStack>
    </YStack>
  )
}

NavBar.Left = Left
NavBar.Right = Right
NavBar.Center = Center
NavBar.Bottom = Bottom

export default NavBar
