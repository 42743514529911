import { ROOT_URL_API } from '@shopper/app/config'
import { httpBatchLink } from '@trpc/client'
import { createTRPCClient } from '@trpc/react-query'
import type { AppRouter } from '@centrito/api'
import { transformer } from '@centrito/api/transformer'

const proxyClient: ReturnType<typeof createTRPCClient<AppRouter>> = createTRPCClient<AppRouter>({
  links: [
    httpBatchLink({
      url: `${ROOT_URL_API}/trpc`,
      transformer,
    }),
  ],
})

export default proxyClient
