import { getQueryClient } from '@shopper/app/api/queryClient'
import { ROOT_URL_API, isProd } from '@shopper/app/config'
import { httpBatchLink, loggerLink } from '@trpc/client'
import { createTRPCNext } from '@trpc/next'
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '@centrito/api'
import { transformer } from '@centrito/api/transformer'

export const trpc: ReturnType<typeof createTRPCNext<AppRouter>> = createTRPCNext<AppRouter>({
  config() {
    return {
      links: [
        loggerLink({
          enabled: (opts) => !isProd || (opts.direction === 'down' && opts.result instanceof Error),
        }),
        httpBatchLink({
          url: `${ROOT_URL_API}/trpc`,
          transformer,
        }),
      ],
      queryClient: getQueryClient(),
    }
  },
  transformer,
  ssr: false,
})

/**
 * Inference helpers for input types
 * @example type HelloInput = RouterInputs['example']['hello']
 **/
export type RouterInputs = inferRouterInputs<AppRouter>

/**
 * Inference helpers for output types
 * @example type HelloOutput = RouterOutputs['example']['hello']
 **/
export type RouterOutputs = inferRouterOutputs<AppRouter>
