import trpcProxyClient from '@shopper/app/api/trpc/proxyClient'
import { PUSHWOOSH_APP_ID } from '@shopper/app/config'
import Sentry from '@shopper/app/sentry/shopper'
import type { PushwooshTags } from '@centrito/common/helpers'
import * as methods from './public'
import type { CustomEventProperties } from './utils/CustomEventProperties'
import { PUSHWOOSH_EVENTS_ENABLED } from './utils/constants'

class NotificationsClient {
  private isInitialized: boolean
  private isRegistered: boolean

  private Pushwoosh: any
  eventsEnabled = PUSHWOOSH_EVENTS_ENABLED

  constructor() {
    this.isInitialized = false
    this.isRegistered = false
  }

  public getIsinitialized(): boolean {
    return this.isInitialized
  }

  public getIsRegistered(): boolean {
    return this.isInitialized && this.isRegistered
  }

  public initialize(): void {
    this.Pushwoosh = (window as any).Pushwoosh || []
    this.Pushwoosh.push([
      'init',
      {
        logLevel: 'error',
        applicationCode: PUSHWOOSH_APP_ID,
        defaultNotificationTitle: 'SamSam',
        defaultNotificationImage: 'https://samsam.co/icon.png',
        autoSubscribe: true,
        subscribeWidget: {
          enable: false,
        },
      },
    ])
    this.isInitialized = true
    this.register()
  }

  public async register(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    this.Pushwoosh.push((api: unknown): void => {
      this.Pushwoosh.subscribe()
      this.isRegistered = true
    })
  }

  public async addReachability(): Promise<void> {
    this.Pushwoosh.getHWID().then((hwid: string) => {
      trpcProxyClient.user.profile.addHardwareId
        .mutate({ hardwareId: hwid, platform: 'web' })
        .catch((error) => {
          Sentry.captureException(error)
        })
    })
  }

  private async removeReachability(hwid: string): Promise<void> {
    try {
      await trpcProxyClient.user.profile.removeHardwareId.mutate({
        hardwareId: hwid,
        platform: 'web',
      })
    } catch (error) {
      console.error('Error removing reachability:', error)
      Sentry.captureException(error)
    }
  }

  public async logout(): Promise<void> {
    try {
      const hwid = await this.Pushwoosh.getHWID()
      await this.removeReachability(hwid)
      this.setUserId(hwid)
    } catch (error) {
      console.error('Error during NotificationsClient logout:', error)
      Sentry.captureException(error)
    }
  }

  private setUserId(userId: string): void {
    this.Pushwoosh.push((api: any): void => {
      api.registerUser(userId)
    })
  }

  public login(userId: string): void {
    this.setUserId(userId)
    this.addReachability()
  }

  public async addTags(tags: PushwooshTags): Promise<void> {
    try {
      if (this.isInitialized && this.eventsEnabled) {
        await new Promise((resolve, reject) => {
          this.Pushwoosh.push((api: { setTags: (tags: PushwooshTags) => Promise<void> }): void => {
            api
              .setTags(tags)
              .then(resolve)
              .catch((error: Error) => {
                Sentry.captureException(error)
                console.error('Error adding tags:', error)
                reject(error)
              })
          })
        })
      } else if (!this.isInitialized) {
        console.error('NotificationsClient: Pushwoosh not initialized')
      } else {
        console.warn('NotificationsClient: Pushwoosh tags not enabled')
      }
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  public unregister(): void {
    this.Pushwoosh.push((api: any): void => {
      api.unregisterDevice()
      this.isRegistered = false
    })
  }

  public async registerEmail({ email }: { email: string }): Promise<void> {
    try {
      await trpcProxyClient.user.profile.registerEmail.mutate({ email })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  public async unregisterEmail({ email }: { email: string }): Promise<void> {
    try {
      await trpcProxyClient.user.profile.unregisterEmail.mutate({ email })
    } catch (error) {
      Sentry.captureException(error)
    }
  }

  public triggerCustomEvent(
    eventName: string,
    customEventProperties?: CustomEventProperties,
  ): void {
    if (this.isInitialized && this.eventsEnabled) {
      methods.triggerCustomEvent(
        eventName,
        (eventName: string, attributes?: Record<string, string>) => {
          this.Pushwoosh.push((api: any): void => {
            api.postEvent(eventName, attributes)
          })
        },
        customEventProperties,
      )
    } else if (!this.isInitialized) {
      console.error('NotificationsClient: Pushwoosh not initialised')
    } else {
      console.warn('NotificationsClient: Pushwoosh events not enabled')
    }
  }
}

export const shopperNotificationsClient = new NotificationsClient()
