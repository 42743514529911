import { type PayloadAction, createSlice } from '@reduxjs/toolkit'
import type {
  MerchantCustomerCartItem,
  MerchantCustomerRetailCartItem,
} from '@shopper/app/store/slices/merchantCustomerData/types'
import { type MerchantCustomerDataState } from '@shopper/app/store/slices/merchantCustomerData/types'
import * as REDUCER_NAMES from '@shopper/app/store/slices/names'
import loadMerchantCatalogData from './loadMerchantCatalogData'

const initialState: MerchantCustomerDataState = {
  cart: {
    items: [],
  },
  retailCart: {
    items: [],
  },
  merchantCatalogProfile: null,
}

const REDUCER_NAME = REDUCER_NAMES.MERCHANT_CUSTOMER_DATA

const merchantCustomerDataSlice = createSlice({
  name: REDUCER_NAME,
  initialState,
  reducers: {
    addItemToCart(state, { payload }: PayloadAction<{ newCartItem: MerchantCustomerCartItem }>) {
      return {
        ...state,
        cart: {
          ...state.cart,
          items: [...state.cart.items, payload.newCartItem],
        },
      }
    },
    updateCartItem(
      state,
      {
        payload,
      }: PayloadAction<{
        id: string
        sizes: MerchantCustomerCartItem['sizes']
        subtotal: number
      }>,
    ) {
      return {
        ...state,
        cart: {
          ...state.cart,
          items: state.cart.items.map((cartItem) =>
            cartItem.id === payload.id
              ? {
                  ...cartItem,
                  sizes: payload.sizes,
                  subtotal: payload.subtotal,
                }
              : cartItem,
          ),
        },
      }
    },
    removeCartItemSize(
      state,
      {
        payload,
      }: PayloadAction<{
        id: string
        name: string
        subtotal: number
      }>,
    ) {
      return {
        ...state,
        cart: {
          ...state.cart,
          items: state.cart.items.map((cartItem) =>
            cartItem.id === payload.id
              ? {
                  ...cartItem,
                  subtotal: payload.subtotal,
                  sizes: cartItem.sizes.filter((size) => size.name !== payload.name),
                }
              : cartItem,
          ),
        },
      }
    },
    removeItemFromCart(
      state,
      {
        payload,
      }: PayloadAction<{
        id: string
      }>,
    ) {
      return {
        ...state,
        cart: {
          ...state.cart,
          items: state.cart.items.filter((cartItem) => cartItem.id !== payload.id),
        },
      }
    },
    resetCart(state) {
      return {
        ...state,
        cart: {
          ...state.cart,
          items: [],
        },
      }
    },
    addItemToRetailCart(
      state,
      { payload }: PayloadAction<{ newRetailCartItem: MerchantCustomerRetailCartItem }>,
    ) {
      return {
        ...state,
        retailCart: {
          ...state.retailCart,
          items: [...state.retailCart.items, payload.newRetailCartItem],
        },
      }
    },
    updateRetailCartItem(
      state,
      {
        payload,
      }: PayloadAction<{
        id: string
        color: string
        size: string
        quantity: number
      }>,
    ) {
      return {
        ...state,
        retailCart: {
          ...state.retailCart,
          items: state.retailCart.items.map((retailCartItem) =>
            retailCartItem.id === payload.id &&
            retailCartItem.color === payload.color &&
            retailCartItem.size === payload.size
              ? {
                  ...retailCartItem,
                  quantity: payload.quantity,
                  subtotal: retailCartItem.retailPrice * payload.quantity,
                }
              : retailCartItem,
          ),
        },
      }
    },
    removeItemFromRetailCart(
      state,
      {
        payload,
      }: PayloadAction<{
        id: string
        color: string
        size: string
      }>,
    ) {
      return {
        ...state,
        retailCart: {
          ...state.retailCart,
          items: state.retailCart.items.filter(
            (retailCartItem) =>
              retailCartItem.id !== payload.id ||
              retailCartItem.color !== payload.color ||
              retailCartItem.size !== payload.size,
          ),
        },
      }
    },
    resetRetailCart(state) {
      return {
        ...state,
        retailCart: {
          ...state.retailCart,
          items: [],
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadMerchantCatalogData.fulfilled,
      (state, { payload }): MerchantCustomerDataState => {
        const { merchant } = payload
        return {
          ...state,
          merchantCatalogProfile: merchant,
        }
      },
    )
  },
})

export const actions = merchantCustomerDataSlice.actions
export default merchantCustomerDataSlice
