import React, { useState } from 'react'
import { type AppProps } from 'next/app'
import { useSupabaseBrowserClient } from '@shopper/app/utils/supabase'
import { SessionContextProvider } from '@supabase/auth-helpers-react'

const SupabaseProvider: React.FC<
  AppProps & {
    children: React.ReactNode
  }
> = ({ children, pageProps }) => {
  const supabaseClient = useSupabaseBrowserClient()
  const [client] = useState(() => supabaseClient)
  return (
    <SessionContextProvider supabaseClient={client} initialSession={pageProps.initialSession}>
      {children}
    </SessionContextProvider>
  )
}

export default SupabaseProvider
