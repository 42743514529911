import type { SerializedError } from '@reduxjs/toolkit'
import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import trpcProxyClient from '@shopper/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@shopper/app/store'
import * as REDUCER_NAMES from '@shopper/app/store/slices/names'
import type { AddCouponPayload } from '@shopper/app/store/slices/userData/types'
import { getCartOwner } from '@shopper/app/store/slices/userData/utils/getCartOwner'
import { TRPCClientError } from '@trpc/react-query'
import type { CartDiscountDataCouponMetadata } from '@centrito/api/nest/platform/database/domain'
import type { CartPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites'

// TODO: Move this elsewhere
const newMiniSerializeError = (error: unknown): SerializedError => {
  if (error instanceof TRPCClientError && error.shape.data.cause) {
    const cause = error.shape.data.cause
    return {
      name: cause.name ?? error.name,
      message: cause.message ?? error.message,
      stack: cause.stack ?? error.stack,
      code: cause.code ?? undefined,
    }
  } else {
    return miniSerializeError(error)
  }
}

const addCoupon = createAsyncThunk<
  {
    newCart: CartPublicComposite
    discountDataCouponMetadata: CartDiscountDataCouponMetadata
  },
  AddCouponPayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/addCoupon`, async (payload, thunkAPI) => {
  const currentState = thunkAPI.getState()
  const cartOwner = getCartOwner(currentState, thunkAPI.dispatch)
  try {
    return await trpcProxyClient.user.cart.addCoupon.mutate({ ...payload, cartOwner })
  } catch (e) {
    return thunkAPI.rejectWithValue(newMiniSerializeError(e))
  }
})
export default addCoupon
